$('.dropdown-item-selections').change(function() {
    if ($(this).children(":selected").attr('class') == 'selections-all'){
        $('#search_form').attr('action', '/');
        $('#search_form').submit();
        return true;
    }
    $('#search_form').attr('action', '/' + $(this).children(":selected").attr('selection'));
    $('#search_form').submit();
});
$('.dropdown-item-category').change(function() {
    if ($(this).children(":selected").attr('class') == 'category-all'){
        $('#search_form').attr('action', '/' + $(this).children(":selected").attr('selection'));
        $('#search_form').submit();
        return true;
    }
    $('#search_form').attr('action', '/' + $(this).children(":selected").attr('selection') + '/' + $(this).children(":selected").attr('categoryId'));
    $('#search_form').submit();
});
$('.dropdown-item-product').change(function() {
    if ($(this).children(":selected").attr('class') == 'product-all'){
        $('#search_form').attr('action', '/' + $(this).children(":selected").attr('selection') + '/' + $(this).children(":selected").attr('categoryId'));
        $('#search_form').submit();
        return true;
    }
    $('#search_form').attr('action', '/' + $(this).children(":selected").attr('selection') + '/' + $(this).children(":selected").attr('categoryId') + '/' + $(this).children(":selected").attr('productId'));
    $('#search_form').submit();
});

$('.item-breadcrumbs-selection').click(function () {
    $('#search_form').attr('action', '/' + $(this).attr('selection'));
    $('#search_form').submit();
});
$('.item-breadcrumbs-category').click(function () {
    $('#search_form').attr('action', '/' + $(this).attr('selection') + '/' + $(this).attr('categoryId'));
    $('#search_form').submit();
});
$('.item-breadcrumbs-product').click(function () {
    $('#search_form').attr('action', '/' + $(this).attr('selection') + '/' + $(this).attr('categoryId') + '/' + $(this).attr('productId'));
    $('#search_form').submit();
});

$('.page-link').click(function () {
    $('#search_form').attr('action', $(this).attr('page'));
    $('#search_form').submit();
});

$('.eco-variant').click(function () {
    $("#search_form")[0].reset();
    $('#search_form_priceMax').val('');
    $('#search_form_priceMin').val('');
    $('#search_form_search').attr('value', $(this).text());
    $('#search_form_ecology').attr('checked', 'checked');
    $('#search_form').attr('action', '/');
    $('#search_form').submit();
});