$(document).ready(function () {
    $('#changedEmailSubmit').click(function () {
        let data = {
            'newEmail': $('#changedEmailInput').val()
        };
        let url  = $('#changedEmailSubmit').attr('data-url');

        $.ajax({
            url: url,
            data: data,
            method: 'post',
            dataType: 'json',
            success: function (response) {
                $('#changedEmailMessage').removeAttr('hidden');
                $('#changedEmailError').remove();
            },
            error: function (response) {
                let result = response.responseJSON;

                for (let key in result.errors) {
                    $('#'+key+'Form').removeClass('error');
                    $('#confirmPasswordForm').removeClass('send');
                    $('#changePasswordMessage').attr('hidden');
                    $('#'+key+'Error').remove();
                    result.errors[key].forEach(function (value) {
                        $('#'+key+'Form').addClass('error');
                        $('#'+key+'Input').after('<span class="error" style="color: red" id="'+key+'Error">'+value+'<br></span>');
                    });
                }
            }
        })
    });

    $('#changePasswordSubmit').click(function () {
        let data = {
            'oldPassword'    : $('#oldPasswordInput').val(),
            'newPassword'    : $('#newPasswordInput').val(),
            'confirmPassword': $('#confirmPasswordInput').val(),
        };

        let url  = $('#changePasswordSubmit').attr('data-url');

        $.ajax({
            url: url,
            data: data,
            method: 'post',
            dataType: 'json',
            success: function (response) {
                $('#changePasswordMessage').removeAttr('hidden');
                $('#confirmPasswordForm').addClass('send').removeClass('error');;

                $('#confirmPasswordError').remove();
                $('#newPasswordError').remove();
                $('#oldPasswordError').remove();

                $('#newPasswordForm').removeClass('error');
                $('#oldPasswordForm').removeClass('error');
            },
            error: function (response) {
                let result = response.responseJSON;

                for (let key in result.errors) {
                    $('#'+key+'Form').removeClass('error');
                    $('#confirmPasswordForm').removeClass('send');
                    $('#changePasswordMessage').attr('hidden');
                    $('#'+key+'Error').remove();
                    result.errors[key].forEach(function (value) {
                        $('#'+key+'Form').addClass('error');
                        $('#'+key+'Input').after('<span class="error" style="color: red" id="'+key+'Error">'+value+'<br></span>');
                    });
                }
            }
        })
    });

    $('#changeNameSubmit').click(function () {
        let data = {
            'firstName' : $('#firstNameInput').val(),
            'lastName'  : $('#lastNameInput').val(),
        };

        let url  = $('#changeNameSubmit').attr('data-url');

        $.ajax({
            url: url,
            data: data,
            method: 'post',
            dataType: 'json',
            success: function (response) {
                location.reload();
            },
            error: function (response) {
                let result = response.responseJSON;

                for (let key in result.errors) {
                    $('#'+key+'Form').removeClass('error');
                    $('#'+key+'Error').remove();
                    result.errors[key].forEach(function (value) {
                        $('#'+key+'Form').addClass('error');
                        $('#'+key+'Input').after('<span class="error" style="color: red" id="'+key+'Error">'+value+'<br></span>');
                    });
                }
            }
        })
    });
});