$('#promo-link').click(function () {
    $('#promo-link').hide();
    $('#promo-input').show();
});

$('#payment-button').click(function () {
    let url = $('#payment-button').attr('data-url');
    let params = $('#promo-input').val();

    window.location.href = url + "?" + $.param({promo_code: params});
});

$('#payment-dashboard-text').click(function () {
    $('#payment-dashboard').show();
    $('#payment-dashboard-text').hide();
});