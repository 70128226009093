$(document).ready(function () {
   var surface_parking;
   var surface_plancher;
   var surface_espace;
   var surface_demolie;
   var surface_facades;
   var volume_batiment;

   $('.category-input').keyup(function () {
      switch ($(this).attr('id')) {
         case 'surface_espace':
            surface_espace = $(this).val();

            surfaceEspaceCalculation(surface_espace);
            break;
         case 'surface_parking':
            surface_parking = $(this).val();

            ifExistParkingInputs();
            break;
         case 'surface_plancher':
            surface_plancher = $(this).val();

            ifExistParkingInputs();
            surfacePlancherCalculation(surface_plancher);
            break;
         case 'surface_demolie':
            surface_demolie = $(this).val();

            surfaceDemolieCalculation(surface_demolie);
            break;
         case 'surface_facades':
            surface_facades = $(this).val();

            surfaceFacadesCalculation(surface_facades);
            break;
         case 'volume_batiment':
            volume_batiment = $(this).val();

            surfaceVolumeCalculation(volume_batiment);
            break;
      }

      totalSum();
      function totalSum(){
         var sumResult = 0;

         $('.result').each(function (key, value) {
            if ( !isNaN(parseFloat($(value).text())) ) {
               var textValue = $(value).text().replace(/ /g,'');
               sumResult = sumResult + parseFloat(textValue) * PRICE_HELPER;
            }
         });

         $('#total_result').text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN(sumResult / PRICE_HELPER)));
      }

      function ifExistParkingInputs() {
         if ( (surface_parking && surface_parking !== '') && (surface_plancher && surface_plancher !== '') ){
            let sum = parseFloat(surface_parking) + parseFloat(surface_plancher);
            surfaceParkingCalculation(sum);
         } else if (surface_parking) {
            surfaceParkingCalculation(parseFloat(surface_parking));
         } else if (surface_plancher) {
            surfaceParkingCalculation(parseFloat(surface_plancher))
         } else {
            $('#vrd_result').text(concatEuroSymbolAndNumberWithSpace(0));
         }
      }

      $('.price-input').keyup(function () {
         switch ($(this).attr('id')) {
            case 'espacesVert_price':
               surfaceEspaceCalculation(surface_espace);
               totalSum();
               break;
            case 'demolitions_price':
               surfaceDemolieCalculation(surface_demolie);
               totalSum();
               break;
            case 'vrd_price':
               ifExistParkingInputs();
               totalSum();
               break;
            case 'traitementDesFacades_price':
               surfaceFacadesCalculation(surface_facades);
               totalSum();
               break;
            case 'chauffageVentilation_price':
               surfaceVolumeCalculation(volume_batiment);
               totalSum();
               break;
            default:
               surfacePlancherCalculation(surface_plancher);
               totalSum();
               break;
         }
      })

   });

   const PRICE_HELPER = 10000;

   function surfaceEspaceCalculation(userNum) {
      let value  = parseFloat($('#espacesVert_price').val()) * PRICE_HELPER;
      let result = Math.floor(value) * userNum;

      $('#espacesVert_result').text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN(result / PRICE_HELPER)));
   }

   function surfaceDemolieCalculation(userNum) {
      let value  = parseFloat($('#demolitions_price').val()) * PRICE_HELPER;
      let result = Math.floor(value) * userNum;

      $('#demolitions_result').text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN(result / PRICE_HELPER)));
   }

   function surfaceParkingCalculation(userNum) {
      let value  = parseFloat($('#vrd_price').val()) * PRICE_HELPER;
      let result = Math.floor(value) * userNum;

      $('#vrd_result').text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN(result / PRICE_HELPER)));
   }

   function surfaceFacadesCalculation(userNum) {
      let value  = parseFloat($('#traitementDesFacades_price').val()) * PRICE_HELPER;
      let result = Math.floor(value) * userNum;

      $('#traitementDesFacades_result').text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN(result / PRICE_HELPER)));
   }

   function surfaceVolumeCalculation(userNum) {
      let value  = parseFloat($('#chauffageVentilation_price').val()) * PRICE_HELPER;
      let result = Math.floor(value) * userNum;

      $('#chauffageVentilation_result').text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN(result / PRICE_HELPER)));
   }

   function surfacePlancherCalculation(userNum) {
      let valueGros               = parseFloat($('#grosOeuvre_price').val()) * PRICE_HELPER;
      let valueFondation          = parseFloat($('#fondationSpeciales_price').val()) * PRICE_HELPER;
      let valueCharpente          = parseFloat($('#charpente_price').val()) * PRICE_HELPER;
      let valueCouverture         = parseFloat($('#couvertureBardage_price').val()) * PRICE_HELPER;
      let valueEntancheite        = parseFloat($('#etancheite_price').val()) * PRICE_HELPER;
      let valueMenuiserieExt      = parseFloat($('#menuiserieExt_price').val()) * PRICE_HELPER;
      let valueMenuiserieInt      = parseFloat($('#menuiserieInt_price').val()) * PRICE_HELPER;
      let valueCloisons           = parseFloat($('#cloisonsDoublage_price').val()) * PRICE_HELPER;
      let valuePlafounds          = parseFloat($('#plafondsSuspendu_price').val()) * PRICE_HELPER;
      let valueRevetementsDurs    = parseFloat($('#revetementsDeSolsDurs_price').val()) * PRICE_HELPER;
      let valueRevetementsSouples = parseFloat($('#revetementsDeSolsSouples_price').val()) * PRICE_HELPER;
      let valueMuraux             = parseFloat($('#peintureRevetementsMuraux_price').val()) * PRICE_HELPER;
      let valueTransports         = parseFloat($('#transportsMecaniques_price').val()) * PRICE_HELPER;
      let valuePlomberie          = parseFloat($('#plomberieSanitaire_price').val()) * PRICE_HELPER;
      let valueElectriciteFort    = parseFloat($('#electriciteCourantFort_price').val()) * PRICE_HELPER;
      let valueElectriciteFaible  = parseFloat($('#electriciteCourantFaible_price').val()) * PRICE_HELPER;
      let valueSerrurerie         = parseFloat($('#serrurerieMetallerie_price').val()) * PRICE_HELPER;
      let valueFalux              = parseFloat($('#fauxPlanchers_price').val()) * PRICE_HELPER;
      let valueParking            = parseFloat($('#parking_price').val()) * PRICE_HELPER;
      let valueEquipements        = parseFloat($('#equipementsSpecialises_price').val()) * PRICE_HELPER;
      let valueIstallation        = parseFloat($('#installationDeChantier_price').val()) * PRICE_HELPER;
      let valueDivers             = parseFloat($('#diversEtSpecifique_price').val()) * PRICE_HELPER;

      $('#grosOeuvre_result')               .text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN((Math.floor(valueGros) * userNum) / PRICE_HELPER)));
      $('#fondationSpeciales_result')       .text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN((Math.floor(valueFondation) * userNum) / PRICE_HELPER)));
      $('#charpente_result')                .text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN((Math.floor(valueCharpente) * userNum) / PRICE_HELPER)));
      $('#couvertureBardage_result')        .text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN((Math.floor(valueCouverture) * userNum) / PRICE_HELPER)));
      $('#etancheite_result')               .text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN((Math.floor(valueEntancheite) * userNum) / PRICE_HELPER)));
      $('#menuiserieExt_result')            .text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN((Math.floor(valueMenuiserieExt) * userNum) / PRICE_HELPER)));
      $('#menuiserieInt_result')            .text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN((Math.floor(valueMenuiserieInt) * userNum) / PRICE_HELPER)));
      $('#cloisonsDoublage_result')         .text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN((Math.floor(valueCloisons) * userNum) / PRICE_HELPER)));
      $('#plafondsSuspendu_result')         .text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN((Math.floor(valuePlafounds) * userNum) / PRICE_HELPER)));
      $('#revetementsDeSolsDurs_result')    .text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN((Math.floor(valueRevetementsDurs) * userNum) / PRICE_HELPER)));
      $('#revetementsDeSolsSouples_result') .text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN((Math.floor(valueRevetementsSouples) * userNum) / PRICE_HELPER)));
      $('#peintureRevetementsMuraux_result').text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN((Math.floor(valueMuraux) * userNum) / PRICE_HELPER)));
      $('#transportsMecaniques_result')     .text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN((Math.floor(valueTransports) * userNum) / PRICE_HELPER)));
      $('#plomberieSanitaire_result')       .text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN((Math.floor(valuePlomberie) * userNum) / PRICE_HELPER)));
      $('#electriciteCourantFort_result')   .text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN((Math.floor(valueElectriciteFort) * userNum) / PRICE_HELPER)));
      $('#electriciteCourantFaible_result') .text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN((Math.floor(valueElectriciteFaible) * userNum) / PRICE_HELPER)));
      $('#serrurerieMetallerie_result')     .text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN((Math.floor(valueSerrurerie) * userNum) / PRICE_HELPER)));
      $('#fauxPlanchers_result')            .text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN((Math.floor(valueFalux) * userNum) / PRICE_HELPER)));
      $('#parking_result')                  .text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN((Math.floor(valueParking) * userNum) / PRICE_HELPER)));
      $('#equipementsSpecialises_result')   .text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN((Math.floor(valueEquipements) * userNum) / PRICE_HELPER)));
      $('#installationDeChantier_result')   .text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN((Math.floor(valueIstallation) * userNum) / PRICE_HELPER)));
      $('#diversEtSpecifique_result')       .text(concatEuroSymbolAndNumberWithSpace(setZeroIfNaN((Math.floor(valueDivers) * userNum) / PRICE_HELPER)));
   }
   
   function concatEuroSymbolAndNumberWithSpace(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d).*)/g, " ").concat('€');
   }

   function setZeroIfNaN(value) {
      if (isNaN(value)) {
         return 0;
      }
      return value;
   }
});


